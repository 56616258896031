/* eslint-disable global-require */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import moment from 'moment';
import { Card, Button, Divider, Spin } from 'antd';
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  DownOutlined,
  UpOutlined
  // ShareAltOutlined
} from '@ant-design/icons';
import BorderButton from './BorderButton';
import ShareModal from './ShareModal';
import VideoModal from './VideoModal';
import PatientCaseFormModal from './PatientCaseFormModal';
import RegisterConfirmationModal from './RegisterConfirmationModal';
import ObjectivesAndWarnings from './ObjectivesAndWarnings';
import { SessionCardIcons } from './SessionCardSections';
import { toggleSessionRegistration } from '../../redux/dataSlice';
import styles from '../../Styles/SessionCard.module.scss';

import config from '../../config';

const Styles = {
  dividerStyle: {
    margin: '10px 0',
    marginTop: 10,
    marginBottom: 10
  },
  actionButtonStyle: {
    borderRadius: 4,
    color: '#3C6279',
    lineHeight: 1,
    padding: '0px 40px',
    fontSize: 17,
    fontFamily: 'Arial',
    marginTop: 5,
    borderWidth: 2
  }
};

const SessionCard = (props) => {
  const { session, mySessions, openPatientCaseForm, altLayout = false, isAnon = false } = props;

  // console.log('openPatientCaseForm', openPatientCaseForm);

  // eslint-disable-next-line import/no-named-as-default-member
  const code = config.getUrlParams('code');
  // eslint-disable-next-line import/no-named-as-default-member
  const share = config.getUrlParams('share');
  // eslint-disable-next-line import/no-named-as-default-member
  const openPatientCaseId = config.getUrlParams('openPatientCaseId');

  const shareOpen = code === session.invitationcode && share;

  const [cardOpen, setCardState] = React.useState(false);

  const [shareModalOpen, setShareModalState] = React.useState(shareOpen);
  const [videoModalOpen, setVideoModalState] = React.useState(false);
  const [registerModalOpen, setRegisterModalState] = React.useState(props.registerModalOpen || false);
  const [registerModalType, setRegisterModalType] = React.useState(props.registerModalType || null);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [patientCaseFormModalOpen, setPatientCaseFormModalOpen] = React.useState(
    parseInt(openPatientCaseId, 10) === session.id
  );

  // console.log('openPatientCaseId', openPatientCaseId, session.id);

  const { rep } = session;

  const isRegistered = Boolean(_.find(mySessions, (sessionId) => sessionId === session.id));

  const isBroadCast = session.is_broadcast;

  useEffect(() => {
    if (openPatientCaseForm) {
      setPatientCaseFormModalOpen(true);
    }
  }, [openPatientCaseForm]);

  const togglePatientCaseFormModal = () => {
    setPatientCaseFormModalOpen(!patientCaseFormModalOpen);
  };

  const renderToggleMoreButton = () => {
    return (
      <Button
        type="primary"
        onClick={() => {
          // if (isBroadCast) {
          //   return;
          // }
          setCardState(!cardOpen);
          ReactGA.event({
            category: 'Toggle Card details',
            action: cardOpen ? 'Close' : 'Open'
          });
        }}
        className="gradient-text"
        id={'sessioncard-more-less'}
        style={{
          width: '100%',
          cursor: 'pointer',
          // opacity: 1,

          border: 'none',
          fontFamily: 'Arial',
          fontWeight: 900,
          fontSize: '16px',
          boxShadow: 'none'
        }}
        size={'small'}
      >
        {cardOpen ? 'LESS' : 'MORE'}{' '}
        {cardOpen ? (
          <UpOutlined color={'#D10027'} fill={'#D10027'} />
        ) : (
          <DownOutlined color={'#D10027'} fill={'#D10027'} />
        )}
      </Button>
    );
  };

  const indicator =
    loading && !success ? (
      <LoadingOutlined style={{ fontSize: 64, marginLeft: -40, color: '#624b78' }} spinning />
    ) : (
      <CheckCircleTwoTone twoToneColor="#00a7b5" style={{ fontSize: 82, marginLeft: -40 }} />
    );

  // const isJoinSessionDisabled = () => {
  //   const date = new Date();
  //   const today = date.toJSON().split('.')[0].split('T')[0];
  //   return session.startdatetime.split('T')[0] !== today;
  // };

  // console.log('isJoinSessionDisabled', isJoinSessionDisabled());

  const updateSuccessStatus = (statusCode, wasRegistered, sessionTitle, speakerName) => {
    if (statusCode === 200) {
      setSuccess(true);
      if (!wasRegistered) {
        togglePatientCaseFormModal();
      }
      setTimeout(() => {
        setSuccess(false);
        setLoading(false);
      }, 500); // a short delay to allow the animation to finish
    } else if (_.includes([401, 406], statusCode)) {
      // setLoading(false);
      // open modal for max session limit or not eligible
      props.toggleInfoModal(statusCode, sessionTitle, speakerName);
      setLoading(false);
    } else if (statusCode === 403) {
      // setLoading(false);
      // open register confirmation for mealIeligibility
      setRegisterModalState(true);
      setRegisterModalType('mealIneligibility');
    }
    // console.log(statusCode);
  };

  const toggleRegistration = (addtionalPayload) => {
    setLoading(true);
    props.toggleSessionRegistration(session, updateSuccessStatus, addtionalPayload);
    ReactGA.event({
      category: 'Session Registration',
      action: `${session.id}: ${isRegistered ? 'Unregister' : 'Register'}`
    });
  };

  return (
    <Card
      className={`${styles['session-card']} sessioncardstandard`}
      key={session.id.toString()}
      title={<span style={{ marginLeft: 24 }}>{moment(session.startdatetime).format('MMM D')}</span>}
      extra={renderToggleMoreButton()}
      style={{
        fontSize: 16,
        minHeight: cardOpen ? 'auto' : 'auto',
        ...props.style
      }}
    >
      <ShareModal open={shareModalOpen} stateAction={setShareModalState} session={session} />
      <VideoModal open={videoModalOpen} stateAction={setVideoModalState} videoUrl={session.video.url} />
      <RegisterConfirmationModal
        open={registerModalOpen}
        modalType={registerModalType}
        stateAction={setRegisterModalState}
        onClose={() => {
          setLoading(false);
          setRegisterModalState(false);
        }}
        onSuccess={toggleRegistration}
        session={session}
      />
      {session.has_patient_case && (
        <PatientCaseFormModal
          modalOpen={patientCaseFormModalOpen}
          toggleModalOpen={togglePatientCaseFormModal}
          meetingId={session.id}
        />
      )}
      <Spin spinning={loading} indicator={indicator}>
        <div className={`${styles.flex} ${styles['mobile-column']}`}>
          <div className={`${styles.flex} flex-column ${styles['session-icons-wrapper']}`}>
            <SessionCardIcons altLayout={altLayout} session={session} />
          </div>
        </div>
        <div style={{ display: cardOpen ? 'block' : 'none' }}>
          <Divider style={Styles.dividerStyle} />
          <ObjectivesAndWarnings session={session} />
          {!isBroadCast && (
            <div className="sub" style={{ color: '#8F9291' }}>
              <span style={{ color: '#54565b', fontWeight: 'bold' }}>Sales Specialist</span>
              <br />
              <span>
                {rep.firstname} {rep.lastname}
              </span>
              <br />
              <span>{rep.phone}</span>
              <br />
              <span>{rep.email}</span>
            </div>
          )}
        </div>

        <Divider style={Styles.dividerStyle} />

        <div
          className={`flex column-reverse ${styles['session-card-footer']}`}
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#5B2C86'
          }}
        >
          {!isAnon && _.includes([16, 17], session.clientprogram) && (
            <Button
              className={`${styles['session-card-footer-share-btn']}`}
              style={{
                border: 'none',
                color: '#3C6279',
                display: 'flex',
                background: 'none',
                boxShadow: 'none',
                fontSize: 16
              }}
              icon={
                <img
                  src={require('../../img/Share_icon.png')}
                  alt="share"
                  style={{ height: 20, marginRight: 5, marginTop: 2 }}
                />
              }
              onClick={() => {
                setShareModalState(true);
                ReactGA.event({
                  category: 'ShareModal',
                  action: 'open'
                });
              }}
            >
              Share
            </Button>
          )}
          <div className={`${styles['inline-flex']} vertical-center horizontal-center`}>
            {/* {!hidePreviewButton && (
              <BorderButton
                style={Styles.actionButtonStyle}
                onClick={() => {
                  setVideoModalState(true);
                  ReactGA.event({
                    category: 'VideoModal',
                    action: `Open: ${session.video.url}`
                  });
                }}
              >
                Preview
              </BorderButton>
            )} */}
            <div
              className={`flex ${styles['flex-column']} ${styles['horizontal-center']} vertical-center`}
              style={{ margin: '0 10px', textAlign: 'center' }}
            >
              {isRegistered && (
                <small
                  style={{
                    width: 100,
                    lineHeight: 1,
                    fontSize: 14,
                    wordBreak: 'keep-all',
                    whiteSpace: 'nowrap'
                  }}
                >
                  You are attending
                </small>
              )}

              <Button
                className={`${styles['session-card-footer-register-btn']}`}
                style={Styles.actionButtonStyle}
                onClick={() => {
                  if (isAnon) {
                    window.location.href = `/auth/register?code=${session.invitationcode}`;
                    return;
                  }
                  if (isRegistered || isBroadCast) {
                    toggleRegistration(); // unregister or register for broadcast
                  } else {
                    setRegisterModalState(true); // open confirmation modal to register
                  }
                }}
              >
                {isRegistered ? 'Unregister' : 'Register'}
              </Button>
            </div>
            {isRegistered && session.join_url && (
              <BorderButton
                wrapperStyle={{ alignSelf: 'center' }}
                text="Join Session"
                // disabled={isJoinSessionDisabled()}
                onClick={() => {
                  window.open(session.join_url, '_blank');
                  ReactGA.event({
                    category: 'Join Session',
                    action: session.join_url
                  });
                }}
              />
            )}
          </div>
        </div>
      </Spin>
    </Card>
  );
};

const mapStateToProps = ({ data }) => {
  const { mySessions } = data;
  return { mySessions };
};

export default connect(mapStateToProps, { toggleSessionRegistration })(SessionCard);
