import React from 'react';
// import _ from 'lodash';
import { Modal, Typography } from 'antd';
import BorderButton from './BorderButton';

const { Title } = Typography;

const InfoModal = (props) => {
  const { open, modalType, handleClose, meetingTitle, speakerName } = props;

  const renderModalText = () => {
    switch (modalType) {
      case 'maxCapacity':
        return 'Unfortunately this event is at capacity. Please view our upcoming events for another virtual program that may interest you.';
      // case 'maxAttendedMeetings':
      //   return "You have already attended a program on this topic and are unable to register for this event. Please check for other XCORPI MASTERS' CLASS topics or visit the Resource Center for additional content.";
      case 'maxAttendedMeetings':
        return `You are unable to register for this program because you have either already attended or are currently registered for the ${meetingTitle} presentation and/or ${speakerName} within this calendar year.`;
      default:
        return 'Something went wrong. Please try again later.';
    }
  };

  return (
    <Modal
      key={'infoModal'}
      visible={open}
      onOk={handleClose}
      onCancel={handleClose}
      destroyOnClose
      footer={null}
      centered
      // width={'80%'}
      style={{ padding: 'auto', top: 10, textAlign: 'center' }}
    >
      <div className="modal" style={{ padding: 25 }}>
        <Title style={{ color: '#5B2C86', fontSize: 22, marginBottom: 30 }}>{renderModalText()}</Title>
        <div className="border-btn-wrpper">
          <BorderButton
            text={'Close'}
            onClick={handleClose}
            type="submit"
            wrapperStyle={{ maxWidth: 200, margin: 'auto', marginBottom: 8 }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default InfoModal;
